import React, { useState, useEffect } from 'react';

const App: React.FC = () => {
  const [data, setData] = useState<string>('');  // 定义 data 为 string 类型

  useEffect(() => {
    (async function () {
      try {
        const response = await fetch(`/api/message?name=Gary`);
        const result = await response.text();  // 定义 API 响应的类型
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);  // 依赖项为空数组，只在组件挂载时调用一次

  return <div>aaa {data}</div>;
}

export default App;